import { Atom, Cmp, Ctrl, state } from ":mods";
import { AModuleSubModule, AStageModule, EditModuleViewProps } from "../../models";
import { getModuleDetails, createSubModule, deleteSubModules, updateSubModule } from "../../apis";
import { For, Match, Show, Switch, createEffect, splitProps } from "solid-js";
import { SubmoduleForm } from "./submodule-form";
import { ModuleInfo } from "./side-info";
import { ROUTES } from "../../const";
import { getModuleRoute, getStageRoute } from "../../methods";
import { ModuleDetails, ModulesList, StageListDetails } from "../../models";

export function EditModuleDetails(
  props: Ctrl.Routes.model.AdminViewProps & {
    onUpdate: (props: AModuleSubModule<false>[]) => void;
    module: AStageModule;
  }
) {
  const [local, data] = splitProps(props, ["$", "onUpdate"]);
  local.$.actions.setLayout({
    title: "Edit Module " + data.module.name,
    sideInfo: [
      {
        title: data.module.name + " info",
        element: () => <ModuleInfo {...data.module} />,
      },
    ],
  });

  console.log("setup module :: ");
  createEffect(() => {
    // console.log("sad :: ", data.module);
  });

  function onCreate(values: AModuleSubModule) {
    // if (!values.objectives) values.objectives = null;
    // console.log("values are ", values);
    // return;
    createSubModule(data.module.id, values)
      .then((obj) => {
        // console.log("obj is :: ", obj);
        const new_arr = [...(data.module.submodule ?? [])];
        new_arr.push({ ...values, id: obj.id });
        local.onUpdate(new_arr as any);
        // $modules_list.set((s) => {
        //   return { ...s, data: [...s.data, { ...values, id: obj.id }] };
        // });
      })
      .catch((res) => {
        console.log("res is :: ", res);
        // const err = res.response.data.error;
        // local.$.actions.pushToast({
        //   type: "error",
        //   message: typeof err === "string" ? err : err.join("\n"),
        // });
      });
  }

  function onUpdate(values: AModuleSubModule, index: number) {
    updateSubModule(values.id, values)
      .then(() => {
        const new_arr = [...data.module.submodule];
        new_arr[index] = { ...new_arr[index], ...values };
        console.log("update ::: ", new_arr);
        local.onUpdate(new_arr as any);
      })
      .catch((res) => {
        console.log("res is :: ", res);
        const err = res.response.data.error;
        local.$.actions.pushToast({
          type: "error",
          message: typeof err === "string" ? err : err.join("\n"),
        });
      });
  }

  function onDelete(indices: number[]) {
    const ids = indices.map((i) => data.module.submodule[i].id);
    const remove = [] as number[];
    deleteSubModules(ids).then((obj) => {
      obj.forEach((item) => {
        if (item.error) {
          local.$.actions.pushToast({
            type: "error",
            message: item.error,
          });
        }
        remove.push(item.id);
      });
      console.log("removed modules ", remove);
      if (remove.length > 0) {
        // $modules_list.set((s) => ({ ...s, data: s.data.filter((x, i) => !remove.includes(x.id)) }));
        const new_arr = data.module.submodule.filter((x, i) => !remove.includes(x.id));
        local.onUpdate(new_arr as any);
        local.$.actions.pushToast({
          type: "success",
          message: "Deleted " + remove.length + " modules",
        });
      }
    });
  }

  async function onClickedItem(id: number) {
    // let route: string = getModuleRoute(stageID, id);
    // if (route) {
    //   $.actions.navigate(route);
    // }
  }

  return (
    <main class="flex flex-col w-full h-full">
      <div class="flex flex-row w-full h-fit">
        <p class="">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
        </p>
      </div>
      <Cmp.List.Table
        rows={data.module.submodule}
        rowClass="w-full flex flex-row  text-20px"
        rowContainerClass="w-full flex flex-row rounded-lg px2 text-20px"
        colsClasses={{
          id: "!w-10% text-start capitalize",
          name: "!w-90% text-start capitalize",
          // actions: "!w-30% capitalize",
        }}
        events={{
          onAllRows({ event, selected }) {
            console.log("action for all is ", event, selected);
            if (event === "delete") {
              onDelete(selected);
            } else if (event === "create") {
              local.$.actions.pushModal({
                title: "Create New Sub Module",
                element: (event) => (
                  <SubmoduleForm
                    onDone={(values) => {
                      event("close");
                      onCreate(values as any);
                    }}
                  />
                ),
              });
            }
          },
          onSingleRow({ event, item, index }) {
            if (event === "delete") {
              onDelete([index]);
            } else if (event === "clicked") {
              onClickedItem(item.id);
            } else if (event === "create") {
              local.$.actions.pushModal({
                title: `Create New Sub Module (Template of ${item.name})`,
                element: (event) => (
                  <SubmoduleForm
                    submoduleID={item.id}
                    onDone={(values) => {
                      event("close");
                      onCreate(values as any);
                    }}
                  />
                ),
              });
            } else if (event === "edit") {
              local.$.actions.pushModal({
                title: `Update Module ${item.name}`,
                element: (event) => (
                  <SubmoduleForm
                    submoduleID={item.id}
                    buttonMsg="Update"
                    onDone={(values) => {
                      event("close");
                      onUpdate(values as any, index);
                    }}
                  />
                ),
              });
            }
          },
        }}
      />
    </main>
  );
}
